import React from 'react';
import { z } from 'zod';
import { TelnyxVerticalTypes, TelynxEntityTypes, TelynxStockExchanges, TelynxUseCaseTypes } from '../constants';
import {
	isRestricted,
	TelnyxBrand,
	TelnyxBrandSchema,
	TelnyxGetBrand,
	TelnyxGetCampaign,
	TelnyxVerticalType,
	TelynxEntityType,
	TelynxStockExchange,
	TelynxUseCaseType,
} from '../types';
import utils from './utils';
import { InfoBoxProps, Link } from '@alpine-iq/ui-library';
import userUtils from './userUtils';

export type ValidResponse = undefined;
export type InvalidResponse = Record<string, string>;
export type ValidationResult = ValidResponse | InvalidResponse;

export function validateTelynxBrandSettings(telnyxSettings: Partial<TelnyxBrand>): ValidationResult {
	const errors: InvalidResponse = {};

	try {
		// If entity type is for profit, require additional fields
		if (telnyxSettings.entityType === 'PUBLIC_PROFIT') {
			z.object({
				stockSymbol: z.string(),
				stockExchange: z.string().refine((value) => isValidTelnyxStockExchange(value as TelynxStockExchange), {
					message: 'Invalid stock exchange',
				}),
			}).parse(telnyxSettings);
		}

		// Perform validation
		TelnyxBrandSchema.parse(telnyxSettings);
	} catch (e) {
		if (e instanceof z.ZodError) {
			e.errors.forEach((error) => {
				errors[error.path.join('.')] = error.message;
			});
		} else if (e instanceof Error) {
			errors._ = e.message;
		}
	}

	if (userUtils.debugMode()) console.log('errors', errors);
	return Object.keys(errors ?? {}).length > 0 ? errors : undefined;
}

export function isTelnyxCampaignApproved(campaign: TelnyxGetCampaign | undefined): boolean {
	return !!campaign?.isTMobileRegistered;
}

export function getDefaultTelnyxSettings(): Partial<TelnyxBrand> | undefined {
	if (isRestricted()) {
		return {
			vertical: 'RETAIL',
		};
	}
	return undefined;
}

export function isValidTelynxVerticalType(verticalType?: TelnyxVerticalType): boolean {
	if (!verticalType) return false;
	if (isRestricted() && verticalType !== 'RETAIL') {
		return false;
	}
	return !!TelnyxVerticalTypes?.[verticalType];
}

export function isValidTelnyxEntityType(entityType?: TelynxEntityType): boolean {
	if (!entityType) return false;
	return !!TelynxEntityTypes?.[entityType];
}

export function isValidTelnyxStockExchange(entityType?: TelynxStockExchange): boolean {
	if (!entityType) return false;
	return !!TelynxStockExchanges?.[entityType];
}

export function isValidTelnyxUseCase(entityType?: TelynxUseCaseType): boolean {
	if (!entityType) return false;
	return !!TelynxUseCaseTypes?.[entityType];
}

// Who is able to view the telnyx registration form
export function canSeeRegistrationForm(): boolean {
	// Canada cannot view
	if (utils.isCanadaBilling()) {
		return false;
	}
	// Message Digital and Dispojoy cannot view
	if (utils.isDispojoy() || utils.isMessageDigitial()) {
		return false;
	}
	// Demo acconts will always be able to view
	if (utils.isDemo()) {
		return true;
	}
	// If the user has a infobipCmpID they cannot view
	return !utils.user.infobipCmpID;
}

type AlertInfo = {
	type: InfoBoxProps['type'];
	message: React.ReactNode;
};

export function getAlertInfoForTelynxBrandSettings(
	telnyxSettings: Partial<TelnyxGetBrand> | Partial<TelnyxBrand> | undefined,
	campaign: TelnyxGetCampaign | undefined,
): AlertInfo | undefined {
	const asGetBrand = (telnyxSettings ?? {}) as Partial<TelnyxGetBrand>;
	const status = asGetBrand?.status ?? undefined;

	const hasFailed = !!asGetBrand?.failureReasons?.length || !!asGetBrand?.failureData;

	if (status === 'REGISTRATION_FAILED' || hasFailed) {
		const { failureData } = asGetBrand;
		return {
			type: 'danger',
			message: (
				<>
					Your information has been reviewed and did not meet the requirements for registration. Please review the information you submitted and make any necessary
					changes. If you have any questions, please contact support.
					{failureData?.category?.length && (
						<>
							<br />
							<br />
							<strong>Reasons for failure:</strong>
							<ul style={{ marginLeft: '20px' }}>
								{failureData.category.map((category, i) => (
									<li
										key={i}
										style={{ listStyleType: 'disc' }}
									>
										{category.description}
									</li>
								))}
							</ul>
						</>
					)}
				</>
			),
		};
	}

	if (status === 'REGISTRATION_PENDING') {
		return {
			type: 'info',
			message: (
				<>
					{/* Your information has been submitted for audit.  Depending on your industry and use case this can take up to 7 days. However, in most cases you can expect
					sub 48 hour completion. We will notify you if your registration fails or information does not meet requirements. Otherwise, you will automatically be
					assigned a campaign registration ID and be able to create campaigns without interference. */}
					Your information has been submitted for review. Due to high demand, this process can take up to 14 days. We will notify you if your registration fails or
					information does not meet requirements. Otherwise, you will automatically be assigned a campaign registration ID and be able to create campaigns without
					interference.
				</>
			),
		};
	}

	const isApproved = isTelnyxCampaignApproved(campaign);

	if (isApproved || utils.user.approvedTelnyxCampaign) {
		return {
			type: 'success',
			message: (
				<>
					Your information has been approved. You can now create and send campaigns. Keep in mind that you are responsible for ensuring that your campaigns are
					compliant with all applicable laws and regulations.
					{utils.isRestrictedIndustry() && (
						<>
							<br />
							<br />
							{/* Provide link to docs */}
							<strong>Need help?</strong> Check out our{' '}
							<Link
								href="https://support.alpineiq.com/10-dlc-for-regulated-industries"
								target="_blank"
								rel="noreferrer"
							>
								documentation
							</Link>
						</>
					)}
				</>
			),
		};
	}

	if (status === 'OK') {
		return {
			type: 'info',
			message: (
				<>
					Your information has been proccessed and we are now provisioning your campaign ID. Once your campaign ID is ready, you will be able to create and send
					campaigns.
				</>
			),
		};
	}

	// Cannabis operators get a special message about 10DLC registration prior to registration
	if (!status && utils.isCannabis()) {
		return {
			type: 'danger',
			message: (
				<>
					Cannabis operators: You may now register for 10DLC cannabis messaging within the AIQ system. We have received approval from carrier aggregators to use a
					specific age gated message delivery flow. AIQ has automated this flow so that you are cleanly transitioned to our new methodology. Previously saved
					campaigns will be set to “Inactive” status automatically and you will not be able to turn them on until you have edited the raw notice message within the
					campaign builder. Net new campaigns created will automatically force the required/ compliant setup.
				</>
			),
		};
	}

	return undefined;
}
