import moment from 'moment';

import { CustomForm } from '../components/settings/admin/AdminBillingPage';
import { CampaignChannelType, RollingBilling } from '../types';
import { Role } from '../types/acl';
import { AllTiers, Fees, FeesV2, MessagingCreditPlans, MessagingDefaults, PackageTypes, User } from '../types/users';
import utils from './utils';

export const userUtils = {
    // get user name from profile
    getName: () => utils.user?.profile?.name || '',
    //* Get users real role
    getRealRole: () => utils.auth.getRole(),
    // * Is input role exactly = to real role
    isRealRole: (x?: Role) => userUtils.getRealRole() === x,
    // * Get users role (role_view) || real role
    getRoleView: () => {
        if (!userUtils.isAdmin(true)) return userUtils.getRealRole()
        const itemStr = localStorage.getItem('aiq:local:role_view');
        if (!!itemStr) return itemStr || userUtils.getRealRole()
        return userUtils.getRealRole()
    },
    // * Set the role the current admin/user is viewing as
    setRoleView: (role?: Role) => {
        if (userUtils.isAdmin(true)) {
            if (userUtils.isRealRole(role)) localStorage.removeItem('aiq:local:role_view')
            else utils.local.setLocal('role_view', role);
            utils.reload()
        }
    },
    // * Is input role exactly = to preview/role_view
    isRoleView: (x?: Role) => userUtils.getRoleView() === x,
    // * has access to the given role
    hasRoleView: (x: Role) => {
        if (userUtils.isRoleView(x)) return true
        const currentWeight = roleWeight[userUtils.getRoleView()]
        return currentWeight >= roleWeight[x]
    },
    hasRealRole: (x: Role) => {
        if (userUtils.isRealRole(x)) return true
        const currentWeight = roleWeight[userUtils.getRealRole()]
        return currentWeight >= roleWeight[x]
    },
    roleHasRole: (currRole: Role, x: Role) => {
        const currentWeight = roleWeight[currRole]
        return currentWeight >= roleWeight[x]
    },
    // * Is this user a super admin (check real role or preview role)
    isSuperAdmin: (real?: boolean) => utils.auth.isSuperAdmin(real ? userUtils.getRealRole() : userUtils.getRoleView()),
    // * Is this user an admin (check real role or preview role)
    isAdmin: (real?: boolean) => !!utils.auth.isAdmin(real ? userUtils.getRealRole() : userUtils.getRoleView()),
    // * Does this user have legacy pricing vs 2023 pricing plan 
    hasLegacyBilling: (user?: User) => ((user || utils.user).fees === undefined && (user || utils.user).feesV2 === undefined) || (Object.keys((user || utils.user).fees || {}).length !== 0) && (Object.keys((user || utils.user).feesV2 || {}).length === 0),
    // * Get correct user Fees or FeesV2 object
    getUserFees: (user?: User) => {
        if (userUtils.hasLegacyBilling(user)) return ((user || utils.user).fees || {}) as Fees
        return ((user || utils.user).feesV2 || {}) as FeesV2
    },
    //* Get users real package
    getRealPackage: (pack: PackageTypes, user?: User) => {
        if (userUtils.hasLegacyBilling(user)) return ('enterprise' as AllTiers)
        return ((userUtils.getUserFees(user) as FeesV2).packages || {})[pack]?.type as AllTiers
    },
    // * Is input packages exactly = to real packages
    isRealPackage: (pack: PackageTypes, tier: AllTiers, user?: User) => {
        return userUtils.getRealPackage(pack, user) === tier
    },
    // * Get users packages (package_view) || real packages
    getPackageView: (pack: PackageTypes) => {
        if (!userUtils.isAdmin(true)) return userUtils.getRealPackage(pack)
        let itemStr = localStorage.getItem(`aiq:local:package_${pack}`);
        if (!!itemStr) return (itemStr || userUtils.getRealPackage(pack)) as AllTiers
        return userUtils.getRealPackage(pack)
    },
    // * Set the packages the current admin/user is viewing as
    setPackageView: (pack: PackageTypes, tier?: AllTiers) => {
        if (userUtils.isAdmin(true)) {
            if (!tier) localStorage.removeItem(`aiq:local:package_${pack}`)
            else utils.local.setLocal(`package_${pack}`, tier);
            utils.reload()
        }
    },
    // * Check if input tier exactly = current tier
    isPackageTier: (pack: PackageTypes, tier: AllTiers, user?: User) => {
        // * Legacy users get access to all for now
        if (userUtils.hasLegacyBilling(user)) return true
        return userUtils.getPackageTier(pack, user) === tier
    },
    // * Check if tier is equal to or lower than current tier
    hasPackageTier: (pack: PackageTypes, tier: AllTiers, user?: User) => {
        // * Legacy users get access to all for now && same with those viewing as admin
        if (userUtils.isAdmin() || userUtils.hasLegacyBilling(user)) return true
        const currentWeight = tierWeight[userUtils.getPackageTier(pack, user)]
        return currentWeight >= tierWeight[tier]
    },
    // * Get user's current package tier
    getPackageTier: (pack: PackageTypes, user?: User, ignoreAdmin?: boolean) => {
        if (userUtils.hasLegacyBilling(user)) return ('enterprise' as AllTiers)
        let packageTier = ((userUtils.getUserFees(user) as FeesV2).packages || {})[pack]?.type as AllTiers
        if (!ignoreAdmin && userUtils.isAdmin(true)) packageTier = userUtils.getPackageView(pack) || packageTier
        return packageTier
    },
    // * Check if tier -> pendingTier is upgrade or downgrade (currentTier < pendingTier)
    isUpgrade: (tier: AllTiers | '', pendingTier: AllTiers | '') => (tierWeight[tier] < tierWeight[pendingTier]),
    // * Does this user have the native app
    hasNativeApp: (user?: User) => {
        const legacy = userUtils.hasLegacyBilling(user), fees = userUtils.getUserFees(user), hasMeta = user?.metadata?.hasNativeApp
        if (legacy) return (!!(fees as Fees)?.nativeAppCharge && !!(fees as Fees).nativeAppStartDate) || hasMeta
        return (!!(fees as FeesV2)?.nativeAppStartDate && !!(fees as FeesV2).nativeAppTermLength) || hasMeta
    },

    // * Is the user bundling their V2 Fees packages
    isBundling: (user?: User) => {
        if (userUtils.hasLegacyBilling(user || utils.user)) return false
        const mTier = userUtils.getPackageTier('marketing', user, true),
            aTier = userUtils.getPackageTier('analytics', user, true),
            baTier = userUtils.getPackageTier('brandAnalytics', user, true),
            lTier = userUtils.getPackageTier('loyalty', user, true),
            dTier = userUtils.getPackageTier('dataops', user, true)

        return (!!mTier && !!lTier && !!dTier && (!!aTier || !!baTier))
    },

    validateBilling: (user: User, config = {} as { customForm?: CustomForm, fees?: Fees, feesV2: FeesV2 }, hasLegacyBilling: boolean) => {
        const errors: string[] = [];
        let newFees: Fees | FeesV2 = {}

        // * Verify legacy billing
        if (hasLegacyBilling) {
            const customForm: CustomForm = config.customForm || {}
            // * Loop over custom form keys, check if each key is required. If required && has no value add an err
            const builtFees = Object.keys(customForm || {}).reduce((payload: Fees, name) => {
                const { value, required } = customForm[name as keyof Fees] || {};
                payload[name as keyof Fees] = value
                if (required && (value === '' || value === null || value === undefined)) errors.push(`${name} field is required`);
                return payload;
            }, {});

            newFees = { ...config?.fees, ...builtFees }

            let billingCreation = newFees.billingCreation || 0;
            if (billingCreation && moment().unix() - billingCreation < 60 * 60) billingCreation += 60 * 60;

            let nativeAppStartDate = newFees.nativeAppStartDate || 0;
            if (nativeAppStartDate && moment().unix() - nativeAppStartDate < 60 * 60) nativeAppStartDate += 60 * 60;

            newFees.billingCreation = billingCreation;
            newFees.nativeAppStartDate = nativeAppStartDate;

        }
        // * Verify V2 billing
        if (!hasLegacyBilling) {
            newFees = Object.assign({}, config.feesV2) as FeesV2

            // * check and update bundle value only if it's not already set
            if (!newFees.coupon) {
                // Get coupon currently on fees object, not that which was passed in
                const currentCoupon = userUtils.getUserFees().coupon
                if (currentCoupon !== '5OFFBUNDLES' && userUtils.isBundling({ feesV2: newFees } as User)) (newFees as FeesV2).coupon = '5OFFBUNDLES'
                else (newFees as FeesV2).coupon = undefined
            }

            // * Set message default values
            const messageDefaults: MessagingDefaults = ((newFees as FeesV2).messagingDefaults || {}) as MessagingDefaults
            const defaults: { [x: string]: number } = {
                mms: 2,
                sms: 1,
                email: 0.1,
                push: 2,
                directMail: 150,
            }

            for (const key in defaults) { // @ts-ignore
                if (!messageDefaults[key]) messageDefaults[key] = defaults[key]
            }


        }
        // @ts-ignore
        if (!newFees.address && !newFees.address?.street) errors.push(`Invalid billing address`);
        if ((newFees as Fees).planType === 2 && ((newFees as Fees)?.firstMonthTransactions || 0) < 10) errors.push('You must set the customers estimate for month 1 transactions in order to use the per transaction billing plan type.')

        return [errors as string[], newFees as Fees | FeesV2] as [string[], Fees | FeesV2]
    },



    // * debug mode getter & setter
    debugMode: () => userUtils.isAdmin(true) && (utils.local.getLocal('debugMode', false) === true),
    setDebugMode: (bool?: boolean) => {
        if (bool === undefined) bool = !userUtils.debugMode()
        if (userUtils.isAdmin(true)) {
            if (!bool) localStorage.removeItem('aiq:local:debugMode')
            else utils.local.setLocal('debugMode', bool);
            utils.reload()
        }
    },

    // * use experimental features getter & setter
    isExperimental: () => userUtils.isAdmin(true) && (utils.local.getLocal('experimental', false) === true),
    setExperimental: (bool?: boolean) => {
        if (bool === undefined) bool = !userUtils.isExperimental()
        if (userUtils.isAdmin(true)) {
            if (!bool) localStorage.removeItem('aiq:local:experimental')
            else utils.local.setLocal('experimental', bool);
            utils.reload()
        }
    },


    isMessageUpgrade: (currPlan?: MessagingCreditPlans, newPlan?: MessagingCreditPlans) => {
        // * If new plan is greater than current plan, return its an upgrade
        return messagingWeight[newPlan || 'messaging-0k'] > messagingWeight[currPlan || 'messaging-0k']
    },

    getMaxCredits: (plan?: MessagingCreditPlans) => {
        return messagingWeight[plan || 'messaging-0k']
    },

    // * -------------
    // * DEBUGGING helpers
    // * These are used to trigger debugging console logs & optional rendering for devs/admins
    // * -------------
    // * is debugging item(s)
    isDebugging: (...args: string[]) => {
        const debugList = userUtils.getDebugging()
        if (debugList.includes('all')) return true
        for (const arg of args)
            if (debugList.includes(arg)) return true
        return false
    },
    // * add a debugging item
    addDebugging: (...args: string[]) => {
        if (userUtils.isAdmin(true)) {
            const currentDebugging = userUtils.getDebugging() // @ts-ignore
            const uniqueDebugging = [...new Set([...currentDebugging, ...args])];
            utils.local.setLocal('debugging', uniqueDebugging);
            utils.reload()
        }
    },
    // * add a debugging item
    delDebugging: (...args: string[]) => {
        if (userUtils.isAdmin(true)) {
            utils.local.setLocal('debugging', userUtils.getDebugging().filter((x: string) => !args.includes(x)));
            utils.reload()
        }
    },
    // * get debugging items
    getDebugging: () => {
        if (userUtils.isAdmin(true)) return (utils.local.getLocal('debugging', []))
        return [] as string[]
    },
    // * clear debugging items
    clearDebugging: () => {
        if (userUtils.isAdmin(true)) localStorage.removeItem('debugging')
    },
    showClock: JSON.parse(localStorage.getItem('aiq:local:showClock') || 'false'),
    setClock: (bool?: boolean) => {
        if (bool === undefined) bool = !userUtils.showClock;
        userUtils.showClock = bool;
        if (!bool) localStorage.removeItem('aiq:local:showClock')
        else utils.local.setLocal('showClock', bool);
    },

    getTotalCreditsUsed: (billing?: Partial<RollingBilling>) => {
        if (!billing) return 0
        return ([
            'MMSCredits',
            'SMSCredits',
            'EmailCredits',
            'PushCredits',
            'DirectMailCredits',
        ] as (keyof RollingBilling)[]).reduce((acc, key) => {
            let value = ((billing as RollingBilling)[key as keyof RollingBilling] || 0)
            if (typeof value !== 'number') value = value.Count || 0
            return acc + (value || 0)
        }, 0)
    },
}

const tierWeight: { [key in AllTiers | '']: number } = {
    enterprise: 2,
    pro: 1,
    'brand-pro': 1,
    starter: 0,
    'brand-starter': 0,
    '': -1,
}

const roleWeight: { [x: string]: number } = {
    admin_superuser: 10,
    admin_director: 9,
    admin_accountmanager: 8,
    owner: 7,
    operator: 6,
    marketinganalyst: 5,
    marketing: 4,
    analyst: 3,
    manager: 2,
    budtender: 1,
    financial: 0,
}

const messagingWeight: { [x: string]: number } = {
    "messaging-0k": 0,
    "messaging-8k": 8000,
    "messaging-20k": 20000,
    "messaging-60k": 60000,
    "messaging-125k": 125000,
    "messaging-300k": 300000,
    "messaging-525k": 525000,
    "messaging-825k": 825000,
    "messaging-1175k": 1175000,
    "messaging-1500k": 1500000,
    "messaging-2000k": 2000000,
    "messaging-2500k": 2500000,
    "messaging-3000k": 3000000,
    "messaging-3500k": 3500000,
    "messaging-6000k": 6000000,
    "messaging-10000k": 10000000,
    "messaging-15000k": 15000000,
    "messaging-20000k": 20000000,
    "messaging-30000k": 30000000,
    "messaging-50000k": 50000000,
}

type EmailListFunc<T extends string | boolean> =
    T extends boolean ? string[] :
    T extends string ? boolean :
    never;

export const adminUtils = {
    canSeeAll: <T extends string | boolean>(email?: T): EmailListFunc<T> => {
        const emails = [
            'admin@alpineiq.com',
            'nick@alpineiq.com',
            'shahzil@alpineiq.com',
            'brynner.doyle@alpineiq.com',
        ]
        // Add a duplicate for each email with .aiq instead of .alpineiq
        emails.push(...emails.map(x => x.replace('alpineiq', 'aiq')))
        if (typeof email === 'boolean') {
            return emails as EmailListFunc<T>;
        }
        return emails.includes(email || utils.auth.getEmail()) as EmailListFunc<T>;
    },
    engineeringTeam: <T extends string | boolean>(email?: T): EmailListFunc<T> => {
        const emails = [
            ...adminUtils.canSeeAll(true),
            'ahmed@alpineiq.com',
            'matthew.auretta@alpineiq.com',
            'robert.lappert@alpineiq.com',
            'cole.yeager@alpineiq.com',
            'mike.knowles@alpineiq.com',
        ];
        if (typeof email === 'boolean') {
            return emails as EmailListFunc<T>;
        }
        return emails.includes(email || utils.auth.getEmail()) as EmailListFunc<T>;
    },
    supportTeam: <T extends string | boolean>(email?: T): EmailListFunc<T> => {
        const emails = [
            ...adminUtils.canSeeAll(true),
            'ben.thedford@alpineiq.com',
            'justin.sevik@alpineiq.com',
            'lindsay.breckheimer@alpineiq.com',
            'taizun@alpineiq.com',
            'juna.branca@alpineiq.com',
            'cameron.patch@alpineiq.com'
        ]
        // Add a duplicate for each email with .aiq instead of .alpineiq
        emails.push(...emails.map(x => x.replace('alpineiq', 'aiq')))
        if (typeof email === 'boolean') {
            return emails as EmailListFunc<T>;
        }
        return emails.includes(email || utils.auth.getEmail()) as EmailListFunc<T>;
    },
    billingTeam: <T extends string | boolean>(email?: T): EmailListFunc<T> => {
        const emails: string[] = [
            'dawn.mason@alpineiq.com',
            'katya.reeve@alpineiq.com',
            'shantay.barrett@alpineiq.com'
        ];
        // Add a duplicate for each email with .aiq instead of .alpineiq
        emails.push(...emails.map(x => x.replace('alpineiq', 'aiq')))
        if (typeof email === 'boolean') {
            return emails as EmailListFunc<T>;
        }
        return emails.includes(email || utils.auth.getEmail()) as EmailListFunc<T>;
    },
    marketingTeam: <T extends string | boolean>(email?: T): EmailListFunc<T> => {
        const emails: string[] = [
            'daniel.aversa@alpineiq.com',
            'jordie.lowe@alpineiq.com',
            'matt.semyck@aiq.com',
        ];
        // Add a duplicate for each email with .aiq instead of .alpineiq
        emails.push(...emails.map(x => x.replace('alpineiq', 'aiq')))
        if (typeof email === 'boolean') {
            return emails as EmailListFunc<T>;
        }
        return emails.includes(email || utils.auth.getEmail()) as EmailListFunc<T>;
    },
    hubspotWhitelist: <T extends string | boolean>(email?: T): EmailListFunc<T> => {
        const emails: string[] = [
            ...adminUtils.canSeeAll(true),
            'ben.thedford@alpineiq.com',
        ];
        // Add a duplicate for each email with .aiq instead of .alpineiq
        emails.push(...emails.map(x => x.replace('alpineiq', 'aiq')))
        if (typeof email === 'boolean') {
            return emails as EmailListFunc<T>;
        }
        return emails.includes(email || utils.auth.getEmail()) as EmailListFunc<T>;
    },
    canViewBilling: <T extends string | boolean>(email?: T): EmailListFunc<T> => {
        const emails: string[] = [
            ...adminUtils.canSeeAll(true),
            ...adminUtils.billingTeam(true),
            'jacob.francis@alpineiq.com',
        ];
        // Add a duplicate for each email with .aiq instead of .alpineiq
        emails.push(...emails.map(x => x.replace('alpineiq', 'aiq')))
        if (typeof email === 'boolean') {
            return emails as EmailListFunc<T>;
        }
        return emails.includes(email || utils.auth.getEmail()) as EmailListFunc<T>;
    },
    isBillingAdmin: <T extends string | boolean>(email?: T): EmailListFunc<T> => {
        const emails: string[] = [
            ...adminUtils.canSeeAll(true),
            ...adminUtils.billingTeam(true),
        ];
        if (typeof email === 'boolean') {
            return emails as EmailListFunc<T>;
        }
        return emails.includes(email || utils.auth.getEmail()) as EmailListFunc<T>;
    },
    canViewUserAlerts: <T extends string | boolean>(email?: T): EmailListFunc<T> => {
        const emails: string[] = [
            ...adminUtils.canSeeAll(true),
            ...adminUtils.marketingTeam(true),
            'ben.thedford@alpineiq.com',
            'ben.cooper@alpineiq.com',
            'sydney.harris@alpineiq.com',
            'justin.baker@alpineiq.com',
        ];
        // Add a duplicate for each email with .aiq instead of .alpineiq
        emails.push(...emails.map(x => x.replace('alpineiq', 'aiq')))
        if (typeof email === 'boolean') {
            return emails as EmailListFunc<T>;
        }
        return emails.includes(email || utils.auth.getEmail()) as EmailListFunc<T>;
    },
    canActivateUserAlerts: <T extends string | boolean>(email?: T): EmailListFunc<T> => {
        const emails: string[] = [
            ...adminUtils.canSeeAll(true),
            ...adminUtils.marketingTeam(true),
            'ben.cooper@alpineiq.com',
        ];
        // Add a duplicate for each email with .aiq instead of .alpineiq
        emails.push(...emails.map(x => x.replace('alpineiq', 'aiq')))
        if (typeof email === 'boolean') {
            return emails as EmailListFunc<T>;
        }
        return emails.includes(email || utils.auth.getEmail()) as EmailListFunc<T>;
    },
}






// @ts-ignore
// biome-ignore lint/complexity/useLiteralKeys: <explanation>
window['userUtils'] = userUtils

export default userUtils
